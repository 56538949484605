import React from "react";
import { Helmet } from "react-helmet";

export default function ScriptsHead() {



    return (

        <Helmet>
			<script async src={`https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GA_AGENZIE_ID}`}></script>
			<script>
				{`
					window.dataLayer = window.dataLayer || [];
					function gtag(){dataLayer.push(arguments);}
					gtag('js', new Date());

					gtag('config', '${process.env.REACT_APP_GA_AGENZIE_ID}');

					gtag('set', {
						'currency': 'EUR'
					});
				`}
			</script>
        </Helmet>
    )
}