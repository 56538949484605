import React, { createContext, useContext } from "react";
import { useQuery } from '@tanstack/react-query';

import { getLocations } from "api/locations";

const Context = createContext({});

export function useLocations() {
    return useContext(Context);
}

export function LocationsContext(props) {

    const {data: locations, isLoading} = useQuery({
        queryKey: ["locations"],
        queryFn: () => getLocations({
            params: {
                sort: {
                    name: 1
                },
                limit: 1000
            }
        }),
        refetchOnMount: false,
        refetchOnWindowFocus: false
    })


    const locationsOptions = locations?.map(location => {
        return {
            label: location.name,
            value: location._id
        }
    }) || []


    return (
        <Context.Provider
            value={{
                locations,
                locationsOptions,
                isLoadingLocations: isLoading
            }}
        >
            {props.children}
        </Context.Provider>
    )
}