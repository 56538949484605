import React, { Fragment } from 'react'

import HeadSeo from 'components/HeadSeo';


export default function Route({route, children}) {

    const { analytics } = route;

    return (
        <Fragment>
            <HeadSeo title={analytics?.name} />
            {children}
        </Fragment>
    )
}