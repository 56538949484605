{
  "name": "@parkagent/agenzie",
  "version": "1.0.0",
  "private": true,
  "author": "Opificio Lamantini Anonimi s.r.l.",
  "type": "module",
  "publishConfig": {
    "registry": "https://npm.parkagent.it"
  },
  "scripts": {
    "start": "HOST=agenzie-$(ifconfig | grep \"inet \" | grep -Fv 127.0.0.1 | awk '{print $2}' | head -n 1).nip.io NODE_ENV=development npm run watch",
    "start:localhost": "HOST=agenzie-127.0.0.1.nip.io NODE_ENV=development npm run watch",
    "test": "HOST=agenzie-$(ifconfig | grep \"inet \" | grep -Fv 127.0.0.1 | awk '{print $2}' | head -n 1).nip.io NODE_ENV=development npm run build",
    "watch": "env-cmd node build.js --watch",
    "build": "env-cmd node build.js",
    "upgrade:dev": "npm install --save @parkagent/ui@dev",
    "upgrade:test": "npm install --save @parkagent/ui@test",
    "upgrade:latest": "npm install --save @parkagent/ui@latest"
  },
  "dependencies": {
    "@heroicons/react": "^2.0.18",
    "@parkagent/ui": "^2.1.63",
    "@phosphor-icons/react": "^2.1.6",
    "@stripe/react-stripe-js": "^2.7.0",
    "@stripe/stripe-js": "^3.3.0",
    "@tanstack/react-query": "^5.13.4",
    "@tanstack/react-query-devtools": "^5.13.5",
    "axios": "^0.27.2",
    "classnames": "^2.3.2",
    "date-fns": "^2.30.0",
    "file-saver": "^2.0.5",
    "formik": "^2.4.1",
    "html2pdf.js": "^0.10.2",
    "js-cookie": "^3.0.5",
    "jwt-decode": "^4.0.0",
    "qrcode.react": "^4.0.1",
    "qs": "^6.10.3",
    "react": "^18.2.0",
    "react-dom": "^18.2.0",
    "react-error-boundary": "^4.0.12",
    "react-helmet": "^6.1.0",
    "react-router-dom": "^6.3.0",
    "react-schemaorg": "^2.0.0",
    "react-scripts": "5.0.1",
    "sass": "^1.69.5",
    "validate.js": "^0.13.1",
    "web-vitals": "^2.1.4"
  },
  "devDependencies": {
    "autoprefixer": "^10.4.17",
    "env-cmd": "^10.1.0",
    "esbuild": "^0.20.0",
    "esbuild-plugin-clean": "^1.0.1",
    "esbuild-plugin-copy": "^2.1.1",
    "esbuild-plugin-eslint": "^0.3.7",
    "esbuild-style-plugin": "^1.6.3",
    "eslint": "^8.56.0",
    "eslint-plugin-react": "^7.33.2",
    "eslint-plugin-react-hooks": "^4.6.0",
    "http-proxy": "^1.18.1",
    "postcss": "^8.4.35",
    "postcss-loader": "^6.2.1",
    "tailwindcss": "^3.4.1"
  }
}
