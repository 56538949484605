import React from 'react';
import { Link } from 'react-router-dom';
import MainContainer from '@parkagent/ui/dist/components/MainContainer';
import PageMainContent from '@parkagent/ui/dist/components/PageMainContent';
import PageWrapper from '@parkagent/ui/dist/components/PageWrapper';
import Title from '@parkagent/ui/dist/components/Title';
import Tile from '@parkagent/ui/dist/components/Tile';
import Paragraph from '@parkagent/ui/dist/components/Paragraph';
import Button from '@parkagent/ui/dist/components/Button';

export default function NotFound() {

    return (
        <PageWrapper>
            <PageMainContent>
                <MainContainer>
                    <Tile>
                        <div className='p-6 bg-extralightprimary'>
                            <Title huge center color="primary">404</Title>
                            <Paragraph center>La pagina che stai cercando non esiste.</Paragraph>

                            <div className='pt-5 flex justify-center'>
                                <Button wAuto to='/' component={Link}>Torna in dashboard</Button>
                            </div>
                        </div>
                    </Tile>
                </MainContainer>
            </PageMainContent>
        </PageWrapper>
    )
}
