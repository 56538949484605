import React from "react";
import {Helmet} from "react-helmet";
import { useLocation } from 'react-router-dom'

const defaultTitle = "ParkAgent - prenota il tuo parcheggio con un click";

function getTitle(titles, skip) {
	let title = skip && !!titles ? "" : defaultTitle;
	titles && titles.map(subTitle => title = `${subTitle} | ${title}`)
	return title;
}

export default function HeadSeo({
	title,
	description,
	type,
	image,
	additions,
	canonical,
	breadcrumbs
}) {

    const location = useLocation();
	const path = location.pathname;

	const defaults = {
		title: getTitle(title),
		description: description || "Il portale parcheggi più usato dalle agenzie viaggi italiane. E' sicuro, economico e veloce. Prenota online, cancellazione gratuita, posto auto riservato senza impegno. Video-sorvegliato assicurato e custodito. Servizio di Navetta incluso. Economico. parcheggi aeroporto porto stazione città.",
		type: type || 'Thing',
		image: image || `${location.origin}/images/covers/home/parkagent-home-1.jpg`,
	}

	let name;
	if(title && Array.isArray(title)) {
		name = title.pop[0];
	} else if(title && !Array.isArray(title)) {
		name = title;
	} else {
		name = defaults.title;
	}

	let jsonLd = {
		"@context": "http://schema.org/",
		"@type": defaults.type,
		"name": name,
		"url": path,
        "image": defaults.image,
		"author": "Parkagent",
		"description": defaults.description,
		...additions
	}

    let canonicalUrl = canonical ? `${location.origin}${canonical}` : location.href;

    const organizationJsonLd = {
		"@context": "https://schema.org/",
		"@type": "Organization",
        "name": "ParkAgent",
		"url": location.origin,
        "logo": `${location.origin}/images/logo-white.svg`
    }

	if (breadcrumbs) {
		jsonLd = {
			"@context":"http://schema.org",
			"@graph": [
				jsonLd,
				{
					"@type":"BreadcrumbList",
					"itemListElement": breadcrumbs.map((item, index) => ({
						"@type":"ListItem",
						"position": index + 1,
						"item": {
							"@id": item.url,
							"name": item.name
						}
					}))
				}
			]
		}
	}

    return (

        <Helmet>
            <meta property="og:type" content="website"/>

            <title itemProp="name" lang="it">{defaults.title}</title>
            <meta name="description" content={defaults.description}/>
            <meta property="og:image" content={defaults.image}/>
            <meta property="og:url" content={canonicalUrl}/>
            <meta property="og:locale" content="it_IT"/>
            <meta property="og:site_name" content={"ParkAgent.it"}/>
            <link rel="canonical" href={canonicalUrl} />
            <link rel="alternate" hrefLang="it" href={canonicalUrl} />
            <link rel="alternate" hrefLang="x-default" href={canonicalUrl} />

            <script type="application/ld+json">
				{`${JSON.stringify(organizationJsonLd)}`}
			</script>

            <script type="application/ld+json">
				{`${JSON.stringify(jsonLd)}`}
			</script>
        </Helmet>
    )
}