import React, { Suspense } from 'react';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter as Router, Route as DomRoute, Routes } from "react-router-dom";
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ErrorBoundary } from "react-error-boundary";
import routes from './routes';

import AppPackage from "../package.json";
import NotFoundPage from './pages/General/NotFoundPage';
import UiPackage from "@parkagent/ui/package.json";

import { UserContext } from 'contexts/user';
import { ActivitiesContext } from 'contexts/activities';
import { AlertContext } from 'contexts/alert';

import './index.css';
import './stylesheets/index.js';
import Route from 'components/Routes';
import { LocationsContext } from 'contexts/locations';
import ScriptsHead from 'components/ScriptsHead';

const queryCache = new QueryClient({
  defaultConfig: { retry: 1 }
})

function fallbackRender({ error }) {
    // Call resetErrorBoundary() to reset the error boundary and retry the render.
    return (
        <div role="alert">
            <p>Something went wrong:</p>
            <pre style={{ color: "red" }}>{error.message}</pre>
        </div>
    );
}

function App() {

    console.log(
        `%c proudly made by Opificio Lamantini Anonimi`,
        "background-color:#3A80B4; font-weight:bold; color:white;"
    );
    console.log(
        `%c parkagent: ${AppPackage?.version}`,
        "background-color:#3A80B4; font-weight:bold; color:white;"
    );
    console.log(
        `%c parkagent-ui: ${UiPackage?.version}`,
        "background-color:#3A80B4; font-weight:bold; color:white;"
    );

    return (
        <QueryClientProvider client={queryCache}>
            <Suspense>
                <ErrorBoundary fallbackRender={fallbackRender}>
                    <Router>
                        <UserContext>
                            <ActivitiesContext>
                                <AlertContext>
                                    <LocationsContext>
                                        <ScriptsHead />
                                        <Routes>
                                            {routes?.map((layout, index) => {
                                                return (
                                                    <DomRoute key={index} element={<layout.layout />} >
                                                        {layout.routes.map((route, indexRoute) => {
                                                            return (
                                                                <DomRoute
                                                                    key={`${index}-${indexRoute}`}
                                                                    {...route}
                                                                    element={
                                                                        <Route route={route}>
                                                                            <route.element/>
                                                                        </Route>
                                                                    }
                                                                >
                                                                    {route?.routes?.map((subroute, subIndex) =>
                                                                        <DomRoute
                                                                            key={`${subIndex}-${index}-${indexRoute}`}
                                                                            {...subroute}
                                                                            element={
                                                                                <Route route={subroute}>
                                                                                    <subroute.element/>
                                                                                </Route>
                                                                            }
                                                                        />
                                                                    )}
                                                                </DomRoute>
                                                            )
                                                        })}
                                                    </DomRoute>
                                                )
                                            })}

                                            <DomRoute
                                                path="*"
                                                render={() => <NotFoundPage />}
                                            />
                                        </Routes>
                                    </LocationsContext>
                                </AlertContext>
                            </ActivitiesContext>
                        </UserContext>
                    </Router>
                </ErrorBoundary>
            </Suspense>

            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    )
}

export default App;
